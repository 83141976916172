type Props = {
  title: string;
  description: string;
  url: string;
  imageUrl: string;
};

const FacebookMetaTags = ({ title, description, url, imageUrl }: Props) => {
  return (
    <>
      <meta property="og:type" content="website" />
      <meta property="og:url" content={url} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={imageUrl} />
    </>
  );
};

export default FacebookMetaTags;
