import Link from 'next/link';
import Image from 'next/image';
import { Colors } from '@kvika/audur-theme';
import { valueOrDefault } from '@kvika/audur-prismic-utils';
import { PrismicRichText } from '@prismicio/react';
import { AudurFooter, AudurLink as AudurLinkType } from '@kvika/audur-prismic-types';
import styles from './Footer.module.scss';
import GridItem from '../grid-container/GridItem';
import AudurLink from '../AudurLink';
import { AudurButtonLink } from '../audur-buttons';
import { SegmentTrackingId, trackEvent } from '../../utils/Analytics';

type Props = {
  footer: AudurFooter;
};

const Footer = ({ footer }: Props) => {
  const phoneNumber = footer.phone_number as AudurLinkType;
  const email = footer.email as AudurLinkType;
  const link = footer.link as AudurLinkType;
  const button = footer.button as AudurLinkType;
  return (
    <>
      <GridItem className="col-1-4">
        <div className={styles.footerColumn}>
          <div className={styles.cta}>
            <Image
              src={footer.image.url}
              alt="Auður Logo"
              width={footer.image.dimensions.width}
              height={footer.image.dimensions.height}
              priority
            />
          </div>
          <div className={styles.contact}>{footer.description && <PrismicRichText field={footer.description} />}</div>
        </div>
      </GridItem>
      <GridItem className="col-2-4">
        <div className={styles.footerColumn}>
          <div className={styles.cta}>
            <Image
              src={phoneNumber.linkImage.url}
              alt="Phone Image"
              width={phoneNumber.linkImage.dimensions.width}
              height={phoneNumber.linkImage.dimensions.height}
              priority
            />
            <AudurLink className={styles.telephone} href={valueOrDefault(phoneNumber.linkUrl, '/')}>
              {valueOrDefault(phoneNumber.linkTitle, '')}
            </AudurLink>
          </div>
          <div className={`${styles.links} ${styles.hideInMobile}`}>
            {footer.first_column?.map((link, index) => {
              const firstColumnLink = link.first_column_link as AudurLinkType;
              return (
                // eslint-disable-next-line react/no-array-index-key
                <Link key={index} href={valueOrDefault(firstColumnLink.linkUrl, '/')}>
                  {valueOrDefault(firstColumnLink.linkTitle, '')}
                </Link>
              );
            })}
          </div>
        </div>
      </GridItem>
      <GridItem className="col-3-4">
        <div className={styles.footerColumn}>
          <div className={styles.cta}>
            <Image
              src={email.linkImage.url}
              alt="Email Image"
              width={email.linkImage.dimensions.width}
              height={email.linkImage.dimensions.height}
              priority
            />
            <AudurLink className={styles.email} href={valueOrDefault(email.linkUrl, '/')}>
              {valueOrDefault(email.linkTitle, '')}
            </AudurLink>
          </div>
          <div className={`${styles.links} ${styles.showInMobile}`}>
            {footer.first_column?.map((link, index) => {
              const firstColumnLink = link.first_column_link as AudurLinkType;
              return (
                // eslint-disable-next-line react/no-array-index-key
                <Link key={index} href={valueOrDefault(firstColumnLink.linkUrl, '/')}>
                  {valueOrDefault(firstColumnLink.linkTitle, '')}
                </Link>
              );
            })}
          </div>
          <div className={styles.links}>
            {footer.second_column?.map((link, index) => {
              const secondColumnLink = link.second_column_link as AudurLinkType;
              return (
                // eslint-disable-next-line react/no-array-index-key
                <Link key={index} href={valueOrDefault(secondColumnLink.linkUrl, '/')}>
                  {valueOrDefault(secondColumnLink.linkTitle, '')}
                </Link>
              );
            })}
          </div>
        </div>
      </GridItem>
      <GridItem className="col-4-4">
        <div className={`${styles.footerColumn} ${styles.loginButtonContainer}`}>
          <div className={styles.cta}>
            <AudurLink href={valueOrDefault(link.linkUrl, '/')}>
              <h5>{valueOrDefault(link.linkTitle, '')}</h5>
            </AudurLink>
          </div>
          <AudurButtonLink
            href={valueOrDefault(button.linkUrl, '/')}
            fillColor={Colors.Red}
            onClick={() => {
              trackEvent({ event: SegmentTrackingId.NetbankiButtonClicked });
            }}
          >
            {valueOrDefault(button.linkTitle, '')}
          </AudurButtonLink>
        </div>
      </GridItem>
    </>
  );
};

export default Footer;
