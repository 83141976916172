import React, { CSSProperties } from 'react';
import { GridPosition, ColumnClass } from '../../types/Types';

type Props = {
  gridPosition?: GridPosition;
  className?: ColumnClass | string;
  style?: CSSProperties;
  children: React.ReactNode;
};

const GridItem = ({ gridPosition, className, style, children }: Props) => {
  return (
    <div
      className={className}
      style={{
        display: 'flex',
        flexDirection: 'column',
        ...gridPosition,
        ...style,
      }}
      suppressHydrationWarning
    >
      {children}
    </div>
  );
};

export default GridItem;
