import { FunctionComponent, CSSProperties } from 'react';
import Link from 'next/link';

import styles from './AudurLink.module.scss';

type Props = {
  href: string;
  className?: string;
  selected?: boolean;
  disabled?: boolean;
  children: JSX.Element | string;
  style?: CSSProperties;
};

const AudurLink: FunctionComponent<Props> = ({
  href,
  className = '',
  selected = false,
  disabled = false,
  children,
  style,
}) => {
  const arrClassName = [styles.linkContainer];
  if (disabled) arrClassName.push(styles.disabled);
  if (selected) arrClassName.push(styles.selected);
  if (className !== '') arrClassName.push(className);
  return (
    <span className={arrClassName.join(' ')} style={style}>
      <Link href={href} passHref>
        {children}
        <div>
          <span className={styles.underline} />
        </div>
      </Link>
    </span>
  );
};
export default AudurLink;
