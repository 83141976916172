import React, { FunctionComponent, useRef } from 'react';
import { Colors } from '@kvika/audur-theme';
import { motion } from 'framer-motion';
import { easeInOut } from '@popmotion/easing';

import { AudurButton } from '../audur-buttons';
import { Path } from '../Path';

type Props = {
  toggleMenuOpen(): void;
  buttonColor: Colors;
  isMenuOpen?: boolean;
};

const transition = { transition: { type: 'just', easing: easeInOut } };

const HamburgerMenu: FunctionComponent<Props> = ({ toggleMenuOpen, buttonColor, isMenuOpen = false }: Props) => {
  const containerRef = useRef(null);
  return (
    <AudurButton onClick={toggleMenuOpen} style={{ width: 48, height: 48, backgroundColor: buttonColor, padding: 0 }}>
      <motion.div initial="closed" animate={isMenuOpen ? 'open' : 'closed'} ref={containerRef}>
        <svg width="23" height="23" viewBox="0 0 23 13">
          <Path
            variants={{
              closed: { opacity: 1, d: 'M 2 2.5 L 20 2.5', ...transition },
              open: { opacity: 1, d: 'M 3 16.5 L 17 2.5', ...transition },
            }}
          />
          <Path
            d="M 2 9.423 L 20 9.423"
            variants={{
              closed: { opacity: 1, ...transition },
              open: { opacity: 0, scaleY: 0, ...transition },
            }}
            transition={{ duration: 0.1 }}
          />
          <Path
            variants={{
              closed: { opacity: 1, d: 'M 2 16.346 L 20 16.346', ...transition },
              open: { opacity: 1, d: 'M 3 2.5 L 17 16.346', ...transition },
            }}
          />
        </svg>
      </motion.div>
    </AudurButton>
  );
};

export default HamburgerMenu;
