import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import dynamic from 'next/dynamic';
import Head from 'next/head';

import { motion, useViewportScroll, useTransform } from 'framer-motion';
import { easeInOut } from '@popmotion/easing';
import { useMediaQuery } from 'react-responsive';
import { Spacing } from '@kvika/audur-theme';
import { AudurFooter, AudurHeader, AudurLink } from '@kvika/audur-prismic-types';

import { DisruptionModeStrings } from '@kvika/audur-strings';
import { decodeBase64String } from '@kvika/string-utils';
import styles from './Layout.module.scss';
import GridContainer from './grid-container/GridContainer';
import TopNavigation from './top-navigation/TopNavigation';
import Footer from './footer/Footer';
import useOrientationChange from '../hooks/useOrientationChange';
import MetaTags from './meta-tags/MetaTags';
import { getNavigationHeaderY } from '../utils/Constants';
import { MetaTagInfo, ServiceStatusMode } from '../types/Types';
import { getKvikaApiClient } from '../utils/Utils';

const MobileMenu = dynamic(() => import('./MobileMenu'), { ssr: false });

type Props = {
  children: JSX.Element | JSX.Element[];
  title?: React.ReactNode | React.ReactNode[] | null;
  metaTags?: MetaTagInfo;
  footer: AudurFooter;
  header: AudurHeader;
};

const Layout = (props: Props) => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [serviceStatusMode, setServiceStatusMode] = useState<ServiceStatusMode>();
  const { scrollYProgress } = useViewportScroll();
  const orientationDimensions = useOrientationChange();
  const isMobile = useMediaQuery({ query: '(max-width: 599px)' });
  const router = useRouter();
  const transformedHeaderY = useTransform(scrollYProgress, [0, 0.1], [0, -getNavigationHeaderY(isMobile)], {
    ease: easeInOut,
  });

  const apiClient = getKvikaApiClient(setServiceStatusMode);

  const { title, metaTags, footer, header, children } = props;

  useEffect(() => {
    if (isMobileMenuOpen) {
      // If orientation is changed with the mobile menu open, toggle it closed
      setIsMobileMenuOpen(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orientationDimensions]);

  useEffect(() => {
    // Dummy call to check if he disruption mode headers have been set
    apiClient.getCountries();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getLinksForMobileMenu = () => {
    const buttons = header.button_group?.map((button) => button.button as AudurLink) ?? [];
    const links = header.link_group?.map((link) => link.link as AudurLink) ?? [];
    return buttons?.concat(links);
  };

  return (
    <>
      <Head>
        {title && <title>{title}</title>}
        {metaTags && (
          <MetaTags
            title={metaTags.title}
            description={metaTags.description}
            url="https://audur.is"
            imageUrl={metaTags.imageUrl}
          />
        )}
      </Head>
      {serviceStatusMode?.serviceStatus && (
        <motion.div className={styles.topInfoBanner}>
          <div>
            {serviceStatusMode?.message
              ? decodeBase64String(serviceStatusMode.message)
              : DisruptionModeStrings.DisruptionModeText}
          </div>
        </motion.div>
      )}
      <motion.div className={styles.navigation} style={{ translateY: transformedHeaderY }}>
        <TopNavigation
          onMobileMenuToggle={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
          isMobileMenuOpen={isMobileMenuOpen}
          header={header}
        />
      </motion.div>

      <motion.div className={styles.pageContent}>
        <motion.div className={styles.content}>{children}</motion.div>
        <div className={styles.footer}>
          <GridContainer
            style={
              isMobile
                ? { paddingTop: Spacing.XLarge, paddingBottom: Spacing.XLarge }
                : { paddingTop: Spacing.Medium, paddingBottom: Spacing.Medium }
            }
          >
            {footer && <Footer footer={footer} />}
          </GridContainer>
        </div>
        <MobileMenu activeRoute={router.pathname} visible={isMobileMenuOpen} links={getLinksForMobileMenu()} />
      </motion.div>
    </>
  );
};

export default Layout;
