import React, { CSSProperties, FunctionComponent } from 'react';
import { useRouter } from 'next/router';
import { AudurHeaderLink_Group, AudurLink as AudurLinkType } from '@kvika/audur-prismic-types';
import { valueOrDefault } from '@kvika/audur-prismic-utils';
import styles from './HeaderLinks.module.scss';
import AudurLink from '../AudurLink';

type Props = {
  style?: CSSProperties;
  links: Array<AudurHeaderLink_Group>;
};

const HeaderLinks: FunctionComponent<Props> = ({ style, links }) => {
  const router = useRouter();
  const isSelected = (linkRoute: string) => linkRoute === router.pathname;
  return (
    <div className={styles.container} style={style}>
      {links.map((item, index) => {
        const link = item.link as AudurLinkType;
        return (
          <AudurLink
            href={valueOrDefault(link.linkUrl, '/')}
            className={styles.item}
            selected={isSelected(valueOrDefault(link.linkUrl, '/'))}
            // eslint-disable-next-line react/no-array-index-key
            key={index}
          >
            {valueOrDefault(link.linkTitle, '')}
          </AudurLink>
        );
      })}
    </div>
  );
};

export default HeaderLinks;
