import { CSSProperties } from 'react';
import styles from './GridContainer.module.scss';

type Props = {
  style?: CSSProperties;
  children: React.ReactNode;
};

const GridContainer = ({ style, children }: Props) => {
  return (
    <div className={styles.gridContainer} style={style} suppressHydrationWarning>
      {children}
    </div>
  );
};

export default GridContainer;
