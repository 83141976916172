type Props = {
  title: string;
  description: string;
  url: string;
  imageUrl: string;
};

const TwitterMetaTags = ({ title, description, url, imageUrl }: Props) => {
  return (
    <>
      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:url" content={url} />
      <meta property="twitter:title" content={title} />
      <meta property="twitter:description" content={description} />
      <meta property="twitter:image" content={imageUrl} />
    </>
  );
};

export default TwitterMetaTags;
