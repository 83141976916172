import { useState, useEffect } from 'react';

export default function useOrientationChange() {
  const hasWindow = typeof window !== 'undefined';

  function getDimensions() {
    const windowWidth = hasWindow ? window.innerWidth : null;
    const windowHeight = hasWindow ? window.innerHeight : null;
    return {
      windowWidth,
      windowHeight,
    };
  }

  const [windowDimensions, setWindowDimensions] = useState(getDimensions());
  function handleOrientationResize() {
    setWindowDimensions(getDimensions());
  }

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (hasWindow) {
      window.addEventListener('orientationchange', handleOrientationResize);
      return () => window.removeEventListener('orientationchange', handleOrientationResize);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasWindow]);

  return windowDimensions;
}
