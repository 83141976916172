import React, { CSSProperties } from 'react';

import { Colors } from '@kvika/audur-theme';

import styles from './MainGridContainer.module.scss';
import GridContainer from './GridContainer';
import { Gutters } from '../../types/Types';

type Props = {
  className?: string;
  backgroundColor?: Colors;
  gutters?: Gutters;
  style?: CSSProperties;
  children: React.ReactNode;
};

const MainGridContainer = ({
  className,
  backgroundColor = Colors.DarkGray,
  gutters = Gutters.Both,
  style,
  children,
}: Props) => {
  const getGutterClassName = (gutters: Gutters) => {
    switch (gutters) {
      case Gutters.Both: {
        return styles.mainGridContainer;
      }
      case Gutters.LeftOnly: {
        return styles.mainGridContainerWithLeftGutter;
      }
      case Gutters.RightOnly: {
        return styles.mainGridContainerWithRightGutter;
      }
      case Gutters.None: {
        return styles.mainGridContainerWithNoGutter;
      }
      default: {
        return '';
      }
    }
  };

  return (
    <div
      className={className ? `${getGutterClassName(gutters)} ${className}` : getGutterClassName(gutters)}
      style={{ backgroundColor, ...style }}
    >
      <GridContainer>{children}</GridContainer>
    </div>
  );
};

export default MainGridContainer;
