import FacebookMetaTags from './FacebookMetaTags';
import TwitterMetaTags from './TwitterMetaTags';

type Props = {
  title: string;
  description: string;
  url: string;
  imageUrl: string;
};

const MetaTags = ({ title, description, url, imageUrl }: Props) => {
  const props = { title, description, url, imageUrl };
  return (
    <>
      <meta name="title" content={title} />
      <meta name="description" content={description} />
      <TwitterMetaTags {...props} />
      <FacebookMetaTags {...props} />
    </>
  );
};

export default MetaTags;
